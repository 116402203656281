import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { Element } from "react-scroll"
import styled, { css } from "styled-components"
import BizInfoCard from "../components/BizInfoCard"
import { media } from "../styles/media"

const StyledDiv = styled.div`
  position: relative;
  height: auto;
  .backGround {
    width: 100%;
    height: 60%;
    position: absolute;
    top: 0;
    z-index: -1;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .bizCon {
    padding: 50px 5%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    position: relative;
    .cardSection {
      width: 75%;
    }
    .headCard {
      background: ${({ theme }) => theme.colors.primary.green};
      color: white;
      border: 2px solid ${({ theme }) => theme.colors.primary.green};
      height: 200px;
      width: 22%;
      padding: 30px;
      h4 {
        font-size: 0.9em;
        margin-top: 0.62em;
      }
    }
  }
  ${() =>
    media.tablet(css`
      .bizCon {
        .headCard {
          order: 1;
          padding: 20px;
        }
        .cardSection {
          order: 2;
        }
      }
      .backGround {
        height: 100vh;
      }
    `)}
  ${() =>
    media.sp(css`
      .bizCon {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-start;
        .headCard {
          width: 100%;
          height: auto;
          margin-bottom: 20px;
        }
        .cardSection {
          width: 100%;
        }
      }
    `)}
`

const ProfileBlock = () => {
  const data = useStaticQuery(query)
  return (
    <Element name="corpProfile">
      <StyledDiv>
        <div className="backGround">
          <Image fluid={data.zoo.backImage.childImageSharp.fluid} />
        </div>
        <div className="bizCon">
          <div className="cardSection">
            <BizInfoCard />
          </div>
          <div className="headCard">
            <h3>
              COMPANY
              <br />
              PROFILE
            </h3>
            <h4>会社情報</h4>
          </div>
        </div>
      </StyledDiv>
    </Element>
  )
}

export const query = graphql`
  {
    zoo: strapiIndexParts {
      backImage {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

export default ProfileBlock
